import React, { useEffect, useState } from 'react';

import CarouselDonationCards from '@/core/components/CarouselDonationCards';
import SchemaOrg from '@/core/components/SchemaOrg';
import Store from '@/core/lib/new-architecture/store';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import type { PageWithLayoutsAndProviders } from '@/core/pages/page.types';
import { QuerySortEnum } from '@/core/types/donation';
import type { CityResult, SubResult } from '@/core/types/geo';

const Home: PageWithLayoutsAndProviders = () => {
  const { t } = useTranslationContext(['common', 'home', 'conversation.chat']);

  const [userCityOrSub, setUserCityOrSub] = useState<CityResult | SubResult | undefined>(undefined);

  const { data: current } = Store.current.useCurrent();

  const queryParams = userCityOrSub ? { sort: QuerySortEnum.DISTANCE, loc: userCityOrSub?.alias, distance: 100 } : {};
  const { data: furnitures } = Store.catalogue.useCatalogue({ size: 20, cat: 'ameublement-meubles-24', ...queryParams });
  const { data: clothings } = Store.catalogue.useCatalogue({ size: 20, cat: 'vetements-vetements-26', ...queryParams });
  const { data: multimedias } = Store.catalogue.useCatalogue({ size: 20, cat: 'multimedia-multimedia-high-tech-19', ...queryParams });
  const { data: home } = Store.home.useHome();

  useEffect(() => {
    if (current) {
      const cityOrSub = current.getCityOrSubResult();
      if (cityOrSub) {
        setUserCityOrSub(cityOrSub);
      }
    }
  }, [current]);

  const SIZE = 20;

  const { data: nearDonations } = Store.catalogue.useCatalogue({ loc: userCityOrSub?.alias, size: SIZE, distance: 20, page: 1 });

  const nearDonationsTitle = userCityOrSub && t('carousels.titles.nearUser', { ns: 'home', city: userCityOrSub.name });

  return (
    <div className="flex flex-col gap-3 lg:gap-5">
      <SchemaOrg schema={home?.getBreadcrumbListSchema(t)} />

      {/* Autour de { userCityOrSub } */}
      {!!nearDonations?.donations.length && userCityOrSub?.alias && <CarouselDonationCards title={nearDonationsTitle} donations={nearDonations} />}

      {/* Fournitures */}
      {!!furnitures?.donations.length && <CarouselDonationCards title={t('carousels.titles.furniture', { ns: 'home' })} donations={furnitures} />}

      {/* Vêtements */}
      {!!clothings?.donations.length && <CarouselDonationCards title={t('carousels.titles.clothing', { ns: 'home' })} donations={clothings} />}

      {/* Multimédia */}
      {!!multimedias?.donations.length && <CarouselDonationCards title={t('carousels.titles.multimedia', { ns: 'home' })} donations={multimedias} />}
    </div>
  );
};

export default Home;
