import React from 'react';

import Icons from '@/core/components/Icons';

interface DonationCardDistanceProps {
  distance: number | null;
}

const DonationCardDistance: React.FC<DonationCardDistanceProps> = ({ distance }) => {
  if (distance === null) {
    return null;
  }

  return (
    <div className="flex items-center gap-1">
      <Icons icon="pin" size="16" />
      <p className="text-caption-primary line-clamp-1 font-normal text-content-primary">{`${distance} km`}</p>
    </div>
  );
};

export default DonationCardDistance;
