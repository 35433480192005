import React from 'react';

interface DonationCardCityProps {
  city: string | null;
}

const DonationCardCity: React.FC<DonationCardCityProps> = ({ city }) => {
  if (!city) return null;

  return <p className="text-caption-primary line-clamp-1 font-normal text-content-primary">{city}</p>;
};

export default DonationCardCity;
