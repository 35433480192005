import React from 'react';

import Image from '@/core/components/Image';

interface DonationCardPhotoProps {
  photo: string;
  title: string;
  height: 196 | 128;
  width: 'full' | 118;
}

const DonationCardPhoto: React.FC<DonationCardPhotoProps> = ({ photo, title, height, width }) => {
  return (
    <div className={`${height === 128 ? 'h-[128px]' : 'h-49'} ${width === 118 ? 'w-[118px]' : 'w-full'} shrink-0 rounded-1`}>
      <div className="size-full overflow-hidden rounded-1">
        <Image
          src={photo.replace('jpg', 'webp')}
          type="image/webp"
          fallback={photo}
          alt={title}
          className="size-full self-center rounded-1 object-cover transition-transform duration-300 hover:scale-110"
          loading="lazy"
          decoding="async"
          height={height}
          width={118}
        />
      </div>
    </div>
  );
};

export default DonationCardPhoto;
