import React from 'react';

interface DonationCardTitleProps {
  title: string;
}

const DonationCardTitle: React.FC<DonationCardTitleProps> = ({ title }) => {
  return <p className="text-caption-primary line-clamp-1 max-h-10 break-all font-semibold text-content-primary">{title}</p>;
};

export default DonationCardTitle;
