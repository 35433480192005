import Bugsnag from '@bugsnag/js';
import { dehydrate } from '@tanstack/react-query';
import type { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

import Query from '@/core/lib/new-architecture/query';
import makeQueryClient from '@/core/lib/query/queryClient';
import base from '@/core/lib/translation/base';
import Home from '@/core/pages/Home';
import HomeLayout from '@/next/layouts/HomeLayout';
import ShareProvider from '@/next/lib/share/ShareProvider.next';

export const getServerSideProps: GetServerSideProps = async () => {
  try {
    const queryClient = makeQueryClient();
    await Promise.all([
      // there is a dependance to categories in the catalogue store
      await Query.categories.useCategories.prefetch?.({ queryClient }),
      await Query.donations.useDonations.prefetch?.({ queryClient, size: 20, cat: 'ameublement-meubles-24' }),
      await Query.donations.useDonations.prefetch?.({ queryClient, size: 20, cat: 'vetements-vetements-26' }),
      await Query.donations.useDonations.prefetch?.({ queryClient, size: 20, cat: 'multimedia-multimedia-high-tech-19' }),
    ]);

    const dehydratedState = dehydrate(queryClient);

    return {
      props: {
        ...(await serverSideTranslations('fr', [...base, 'home', 'conversation.chat'])),
        path: '/',
        dehydratedState,
      },
    };
  } catch (err) {
    Bugsnag.notify(err as Error);

    return {
      props: {
        ...(await serverSideTranslations('fr', [...base, 'home', 'conversation.chat'])),
        path: '/',
      },
    };
  }
};

Home.getProviders = page => {
  return <ShareProvider>{page}</ShareProvider>;
};

Home.getLayouts = page => {
  return <HomeLayout>{page}</HomeLayout>;
};

Home.footer = { catalogue: true };

export default Home;
