import 'dayjs/locale/fr';

import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';

import Icons from '@/core/components/Icons';

dayjs.locale('fr');
dayjs.extend(relativeTime);

interface DonationCardDateProps {
  date: Dayjs;
}

const DonationCardDate: React.FC<DonationCardDateProps> = ({ date }) => {
  const lastPublishedAt = date.fromNow(true);

  return (
    <div className="flex items-center gap-1">
      <Icons icon="clock" size="16" />
      <p className="text-caption-primary line-clamp-1 font-normal text-content-primary">{lastPublishedAt}</p>
    </div>
  );
};

export default DonationCardDate;
